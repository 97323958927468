import axiosIns from '@/libs/axios'

export function getCode({ phone }) {
  return axiosIns.post('/password-reset/get-code', {
    phone,
  })
}

export function checkCode({ code, session }) {
  return axiosIns.post('/password-reset/check-code', {
    code,
    session,
  })
}

export function setNewPassword({
  session, password, password_confirmation,
}) {
  return axiosIns.post('/password-reset/set-new-password', {
    session,
    password,
    password_confirmation,
  })
}

export default {
  getCode,
  checkCode,
  setNewPassword,
}
