<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo-app class="h-auto w-auto" />
        </b-link>

        <b-card-title class="mb-1">
          Забыли пароль? 🔒
        </b-card-title>
        <b-card-text
          v-if="stepDescription"
          class="mb-2"
        >
          {{ stepDescription }}
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="submit"
        >
          <small
            v-if="!!error_message"
            class="text-danger d-block mb-1"
          >
            {{ error_message }}
          </small>
          <template v-if="step === 'get-code'">
            <!-- phone -->
            <b-form-group
              label="Телефон"
              label-for="phone"
            >

              <b-form-input
                id="phone"
                v-model.trim="phone"
                v-mask="'+7 (999) 999-99-99'"
                :state="get(validation, 'phone[0]') ? false : null"
                name="register-phone"
                placeholder="+7 (___) ___-__-__"
              />
              <small
                v-if="!!get(validation, 'phone[0]')"
                class="text-danger"
              >
                {{ get(validation, 'phone[0]') }}
              </small>
            </b-form-group>
          </template>
          <div v-else-if="step === 'check-code'">
            <!-- code -->
            <b-form-group
              label="Код"
              label-for="code"
            >

              <b-form-input
                id="code"
                v-model.trim="code"
                :state="get(validation, 'code[0]') ? false : null"
                name="register-code"
              />
              <small
                v-if="!!get(validation, 'code[0]')"
                class="text-danger"
              >
                {{ get(validation, 'code[0]') }}
              </small>
            </b-form-group>
          </div>
          <div v-else-if="step === 'set-new-password'">
            <!-- password -->
            <b-form-group
              label="Пароль *"
              label-for="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="{
                  'is-invalid': !!get(validation, 'password[0]')
                }"
              >
                <b-form-input
                  id="password"
                  v-model.trim="password"
                  :type="passwordFieldType"
                  :state="get(validation, 'password[0]') ? false : null"
                  class="form-control-merge"
                  name="register-password"
                  placeholder="Введите пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="!!get(validation, 'password[0]')"
                class="text-danger"
              >
                {{ get(validation, 'password[0]') }}
              </small>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
              label="Подтвердите пароль *"
              label-for="password_confirmation"
            >
              <b-input-group
                class="input-group-merge"
                :class="{
                  'is-invalid': !!get(validation, 'password_confirmation[0]')
                }"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model.trim="password_confirmation"
                  :type="passwordConfirmationFieldType"
                  :state="get(validation, 'password_confirmation[0]') ? false : null"
                  class="form-control-merge"
                  name="register-password_confirmation"
                  placeholder="Подтвердите пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordConfirmationToggleIcon"
                    class="cursor-pointer"
                    @click="passwordConfirmationFieldType = passwordConfirmationFieldType === 'password' ? 'text' : 'password'"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="!!get(validation, 'password_confirmation[0]')"
                class="text-danger"
              >
                {{ get(validation, 'password_confirmation[0]') }}
              </small>
            </b-form-group>
          </div>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            <template v-if="!sending">
              Отправить
            </template>
            <template v-else>
              <b-row
                align-v="center"
                align-h="center"
                no-gutters
              >
                <b-col cols="auto">
                  <b-spinner
                    small
                    class="mr-1"
                  />
                </b-col>
                <b-col cols="auto">
                  Loading...
                </b-col>
              </b-row>
            </template>
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Уже есть аккаунт? </span>
          <b-link :to="{name:'login'}">
            <span>Войти</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { get, has } from 'lodash'
import { getCode, checkCode, setNewPassword } from '@/services/main-api/password-reset'
import LogoApp from '@/components/LogoApp.vue'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BRow,
    BCol,
    LogoApp,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sending: false,
      step: 'get-code',
      phone: '',
      session: '',
      code: '',

      password: '',
      password_confirmation: '',

      validation: {},
      error_message: '',

      passwordConfirmationFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    stepDescription() {
      let description = ''
      if (this.step === 'get-code') {
        description = 'Введите номер телефона'
      } else if (this.step === 'check-code') {
        description = 'Для подтверждения того, что номер телефона пренадлежит вам, введи код из СМС'
      } else if (this.step === 'set-new-password') {
        description = 'Придумайте новый пароль'
      }
      return description
    },
  },
  methods: {
    async submit() {
      if (this.step === 'get-code') {
        await this.getCodeStep()
      } else if (this.step === 'check-code') {
        await this.checkCodeStep()
      } else if (this.step === 'set-new-password') {
        await this.setNewPassword()
      }
    },
    async getCodeStep() {
      this.clearValidation()
      const phone = this.phone.replace(/[^+\d]/g, '')
      let error = false

      if (!phone) {
        error = true
        this.validation.phone = ['Укажите ваш телефон']
      }

      if (error) {
        return
      }

      this.sending = true

      try {
        const res = await getCode({
          phone,
        })

        this.session = res.data.session

        if (res.data.code) {
          this.code = res.data.code
        }

        this.step = 'check-code'
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    async checkCodeStep() {
      this.clearValidation()

      let error = false

      if (!this.code) {
        error = true
        this.validation.code = ['Укажите код из смс']
      }
      if (error) {
        return
      }

      this.sending = true

      try {
        await checkCode({
          code: this.code,
          session: this.session,
        })

        this.step = 'set-new-password'
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    async setNewPassword() {
      this.clearValidation()
      const phone = this.phone.replace(/[^+\d]/g, '')

      let hasError = false

      if (!this.password) {
        this.validation.password = ['Введите пароль']
        hasError = true
      }

      if (!this.password_confirmation) {
        this.validation.password_confirmation = ['Подтвердите пароль']
        hasError = true
      }

      if (this.password && this.password_confirmation && this.password !== this.password_confirmation) {
        this.validation.password = ['Пароль не совпадает']
        this.validation.password_confirmation = ['Пароль не совпадает']
        hasError = true
      }
      if (hasError) {
        return
      }

      this.sending = true

      try {
        await setNewPassword({
          session: this.session,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })

        await this.$store.dispatch('auth/login', {
          login: phone,
          password: this.password,
        })
        this.clearForm()
        await this.$router.push({ name: 'dashboard' })
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    catchValidation(e) {
      if (has(e.response, 'data.errors') || has(e.response, 'data.error')) {
        this.validation = get(e.response, 'data.errors')
        this.error_message = get(e.response, 'data.error', '')
      } else {
        const error = get(e.response, 'data')
        if (typeof error === 'string') {
          this.error_message = error
        } else {
          this.validation = error
        }
      }
    },
    clearValidation() {
      this.validation = {}
      this.error_message = ''
    },
    get,
    clearForm() {
      this.sending = false
      this.step = 'get-code'
      this.phone = ''
      this.session = ''
      this.code = ''
      this.name = ''
      this.password = ''
      this.password_confirmation = ''
      this.patronymic = ''
      this.surname = ''
      this.validation = {}
      this.error_message = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
